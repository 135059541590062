import React from "react";
import { graphql } from "gatsby";
import Tag from "../components/tag";

export const query = graphql`
  query($slug: String!, $formatString: String!) {
    allPost(
      sort: { fields: date, order: DESC }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        slug
        title
        date(formatString: $formatString)
        excerpt
        timeToRead
        description
        tags {
          name
          slug
        }
      }
    }
  }
`;

type Props = {
  data: {
    allPost: any;
    [key: string]: any;
  };
  pageContext: any;
};

export default function MinimalBlogCoreTag({ data, pageContext }: Props) {
  const { allPost } = data;

  return <Tag posts={allPost.nodes} pageContext={pageContext} />;
}
